@import '../../../scss/utilities';

.headline {
  color: $headings-color;
  font-family: $ue-header-font;
  line-height: 1;
  &--primary {
    padding: $grid-gutter-width * 2 0;
    text-align: center;
    font-size: $ue-font-size-4;
    @include media-breakpoint-up(md) {
      font-size: $ue-font-size-2;
    }
    @include media-breakpoint-up(lg) {
      font-size: $ue-font-size-1;
    }
  }
  &--secondary {
    padding: $grid-gutter-width 0;
    font-size: $ue-font-size-6;
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: $ue-font-size-4;
    }
  }
  &--centered {
    text-align: center;
  }
}
